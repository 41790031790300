<template>

  <div 
    :class="{'show': true, 'is-more': isMore, 'active': isActive, 'max-height': true, 'disabled': isDisabled}"
    
    >
    <div class="cont"
    :style="{'max-height': (props.desktopMaxHeight || props.mobileMaxHeight) + 'px'}"
    >
      <div ref="elContent" class="content">
        <slot></slot>
      </div>
    </div>
    <div v-if="!isMore" @click="toggle" class="toggler more">Show more <i class="icon-arrow-bottom"></i></div>
    <div v-else @click="toggle" class="toggler less">Show less <i class="icon-arrow-top"></i></div>
  </div>
  
</template>

<script setup>
 
const props = defineProps({
  desktopMaxHeight: {
    type: Number,
    default: 10000
  },
  mobileMaxHeight: {
    type: Number,
    default: 140
  },
});

const isActive = ref(false);
const isMore = ref(false);
const isDisabled = ref(true);
const elContent = ref(null);
 
const toggle = () => {
  isMore.value = !isMore.value;
}


onMounted(() => {
  if(import.meta.client){
    document.documentElement.style.setProperty('--show-more-desktop-max-height', props.desktopMaxHeight + 'px')
    document.documentElement.style.setProperty('--show-more-mobile-max-height', props.mobileMaxHeight + 'px')
   
    if(window.innerWidth <= 600){
      isDisabled.value = elContent.value.clientHeight <= props.mobileMaxHeight;
    }else{
      isDisabled.value = elContent.value.clientHeight <= props.desktopMaxHeight;
    }
  }
})
 

</script>

<style scoped>

.toggler{
  margin-bottom: 1rem;
  display: none;
  width: fit-content;
  cursor: pointer;
}
.show{
  position: relative;
}
.show .cont{
  position: relative;
  overflow: hidden;
  max-height: var(--show-more-desktop-max-height);
  transition: max-height 0.5s ease;
}

/* 
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
*/

.show.is-more .cont::after{
  display: none;
}
.show.is-more .cont{
  max-height: fit-content!important;
}

.show .toggler{
  display: block;
}
.toggler.more{
  position: relative;
  z-index: 1;
}

@container pb (max-width: 600px){
  .show .cont{
    max-height: var(--show-more-mobile-max-height);
  }
}

/* disabled */

.show.disabled .toggler{
  display: none;
}
.show.disabled .cont::after{
  display: none;
}

</style>
